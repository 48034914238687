import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import CardEditorial from 'components/CardEditorial';
import testData from 'components/CardEditorial/test.data';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "sandbox"
    }}>{`Sandbox`}</h1>
    <p>{`This page is used for active development. It is not documentation.`}</p>
    <Playground __position={0} __code={'<CardEditorial\n  image={testData.featuredImage.url}\n  title={testData.title}\n  teaser={testData.teaser}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      CardEditorial,
      testData
    }} mdxType="Playground">
    <CardEditorial image={testData.featuredImage.url} title={testData.title} teaser={testData.teaser} mdxType="CardEditorial" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      